import React, { Component } from "react";
import SideNavbarItem from "./SideNavbarItem";
import { isMobile } from "react-device-detect";
import "./SideNavbar.css";

class SideNavbar extends Component {
  componentDidMount() {
    if (isMobile) {
      document.getElementById("SideNavbar").style.height = "auto";
    }
  }

  render() {
    return (
      <div className="SideNavbar" id="SideNavbar">
        <strong className="SideNavbarHeader">
          Hilbert <br />
          Place
        </strong>
        <SideNavbarItem
          header="Foundation"
          items={["Introduction", "Wave Functions", "Observables", "Schrödinger Equation", "Measurement"]}
        />
        <SideNavbarItem
          header="Potentials"
          items={[
            "Infinite Square Well",
            "Harmonic Oscillator",
            "Free Particle",
            // "Quantum Memory (Tunneling)",
          ]}
        />
        <SideNavbarItem header="3 Dimensions" items={["Schrödinger Equation in 3D", "Hydrogen Atom"]} />
        {/* <SideNavbarItem header="Addendum" items={["Fourier Analysis", "Uncertainty Principle"]} /> */}
        <SideNavbarItem header="Additional" items={["About Me"]} />
      </div>
    );
  }
}

export default SideNavbar;
