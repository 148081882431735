import React, { Component, Suspense, lazy } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Loading from "../utils/Loading";
import "./PageContainer.css";

const Introduction = lazy(() => import("./physics/Introduction"));
const WaveFunctions = lazy(() => import("./physics/WaveFunctions"));
const Observables = lazy(() => import("./physics/Observables"));
const SchrödingerEquation = lazy(() => import("./physics/SchrödingerEquation"));
const Measurement = lazy(() => import("./physics/Measurement"));
const InfiniteSquareWell = lazy(() => import("./physics/InfiniteSquareWell"));
const HarmonicOscillator = lazy(() => import("./physics/HarmonicOscillator"));
const FreeParticle = lazy(() => import("./physics/FreeParticle"));
const SchrödingerEquationIn3D = lazy(() => import("./physics/SchrödingerEquationIn3D"));
const HydrogenAtom = lazy(() => import("./physics/HydrogenAtom"));
const AboutMe = lazy(() => import("./other/AboutMe"));
const PageNotFound = lazy(() => import("./other/PageNotFound"));

class PageContainer extends Component {
  render() {
    return (
      <div className="PageContainer">
        <div className="Pages">
          <div className="Page">
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route path="/" exact component={Introduction} />
                <Route path="/introduction" component={Introduction} />
                <Route path="/wave-functions" component={WaveFunctions} />
                <Route path="/observables" component={Observables} />
                <Route path="/schrödinger-equation" component={SchrödingerEquation} />
                <Route path="/measurement" component={Measurement} />
                <Route path="/infinite-square-well" component={InfiniteSquareWell} />
                <Route path="/harmonic-oscillator" component={HarmonicOscillator} />
                <Route path="/free-particle" component={FreeParticle} />
                <Route path="/schrödinger-equation-in-3d" component={SchrödingerEquationIn3D} />
                <Route path="/hydrogen-atom" component={HydrogenAtom} />
                <Route path="/about-me" component={AboutMe} />
                <Route component={PageNotFound} />
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PageContainer);
