import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import "./SideNavbarItem.css";

class SideNavbarItem extends Component {
  render() {
    return (
      <div className="SideNavbarItem">
        <strong className="header">{this.props.header}</strong>
        {this.props.items.map((item) => {
          return (
            <Link
              key={item}
              className="link"
              to={"/" + item.replace(/\s+/g, "-").toLowerCase()}
            >
              {item}
            </Link>
          );
        })}
      </div>
    );
  }
}

export default withRouter(SideNavbarItem);
