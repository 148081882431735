import React, { useEffect } from "react";
import PageContainer from "./components/pages/PageContainer";
import SideNavbar from "./components/sideNavbar/SideNavbar";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import GA from "./config.json";
import ScrollToTop from "./components/ScrollToTop";
import "./App.css";

ReactGA.initialize(GA.GA_TRACKING_ID);
const browserHistory = createBrowserHistory();
browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <Router history={browserHistory} onUpdate={() => window.scrollTo(0, 0)}>
        <ScrollToTop />
        <SideNavbar />
        <PageContainer />
      </Router>
    </div>
  );
};

export default App;
